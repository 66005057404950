import React, { useState, useEffect } from "react";
import { fetchAllData } from "../../../fetcher";

const Dashboard = () => {
    // const [loading, setLoading] = useState(true);
    // const [data, setData] = useState({});
    // const currentCheck = JSON.parse(sessionStorage.getItem("prodCheck"));
    // const [prodChecked, setProdChecked] = useState(currentCheck === false ? currentCheck : true);

    const [userData, setUserData] = useState([]);

    useEffect(() => {
        const getData = async () => {
            const paths = {
                "userData": `/general/spotusers`,
            }
            const data = await fetchAllData(paths);
            setUserData(data.userData);
        }

        getData();
    }, []);

    const pinData = [];
    if (userData.length > 0) {
        userData.forEach((user) => {
            if (user.lotRequests) {
                for (let i in user.lotRequests) {
                    pinData.push({
                        pinCoordinates: user.lotRequests[i].coordinates,
                        userName: user.settings.personalInfo.firstName + " " + user.settings.personalInfo.lastName,
                    })
                }
            }
        });
    }

    // const organizations = 4;
    // const generalTables = [
    //     "organizations",
    //     "announcements",
    //     "spotUsers",
    // ];

    // const createPaths = () => {
    //     const paths = {};
    //     generalTables.forEach((table) => {
    //         paths[table] = `/general/${table}`;
    //     });
    //     for (let i = 1; i <= organizations; i++) {
    //         const orgId = i.toString().padStart(6, "0");
    //         paths[`${orgId}/spots`] = `/${orgId}/spots`;
    //         paths[`${orgId}/history`] = `/${orgId}/history`;
    //         paths[`${orgId}/permits`] = `/${orgId}/permits`;
    //     }

    //     // Tester
    //     paths["test"] = "/test";
    //     return paths;
    // };

    // const paths = createPaths();

    // const storeData = async (paths) => {
    //     try {
    //         const orgResponse = await fetchAllData({ "allOrgData": "/general/organizations" });
    //         sessionStorage.setItem("allOrgData", JSON.stringify(orgResponse.allOrgData));
    //         const response = await fetchAllData(paths, prodChecked ? "prod" : "dev");
    //         sessionStorage.setItem("allOrgData", JSON.stringify(response.organizations));
    //         setData(response);
    //         setLoading(false);
    //         loading ? console.log("Data stored successfully") : console.error("Error storing data");
    //     } catch (error) {
    //         console.error("Error storing data:", error);
    //     }
    // };

    // useEffect(() => {
    //     storeData(paths);
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, []);

    // const ApiBlock = ({ title }) => {
    //     const [message, setMessage] = useState(null);
    //     const [status, setStatus] = useState(null);


    //     useEffect(() => {
    //         try {
    //             const tableData = data[title];
    //             setStatus(tableData?.message || tableData?.error || tableData === null || tableData === undefined ? "Offline" : "Online")
    //             setMessage(status === tableData?.error || tableData?.message || tableData === undefined ? "Failed" : tableData?.error || tableData?.message || `${tableData.length} items`);
    //         } catch (error) {
    //             setMessage(error.toString());
    //         }
    //         // eslint-disable-next-line
    //     }, [data]);

    //     const handleClick = async () => {
    //         try {
    //             const path = { title: paths[title] };
    //             const response = await fetchAllData(path, prodChecked ? "prod" : "dev");
    //             const tableData = response.title;
    //             console.log(tableData);

    //             setStatus(tableData?.message || tableData?.error || tableData === null || tableData === undefined ? "Offline" : "Online")

    //             console.log(status)

    //             setMessage(status === tableData?.error || tableData?.message || tableData === undefined ? "Failed" : tableData?.error || tableData?.message || `${tableData.length} items`);

    //         } catch (error) {
    //             setMessage(error.toString());
    //         }
    //     };

    //     return (
    //         <div className="fcc">
    //             <div
    //                 className={`flex flex-col justify-center cursor-pointer h-fit min-w-fit transition-all duration-150 ease-in-out max-w-48 text-wrap shadow-xl rounded-xl p-4 border ${status === "Offline" ? "bg-red-200" : "bg-green-100"}`}
    //                 onClick={handleClick}
    //             >
    //                 <h1 className="w-full text-center text-wrap">{title}</h1>
    //                 <p className="w-full text-center text-wrap text-sm truncate">{message}</p>
    //             </div>
    //         </div>
    //     );
    // }

    // const handleCheck = () => {
    //     const newState = !prodChecked;
    //     console.log(newState)
    //     setProdChecked(!prodChecked);
    //     sessionStorage.setItem("prodCheck", !prodChecked);
    // }

    return (
        <div className="ml-20 p-8">
            {/* <div className="frc gap-4">
                <div className={`fcc`}>
                    <input type="checkbox" id="checkbox1" checked={prodChecked} onChange={handleCheck} />
                    <label htmlFor="checkbox1">Prod</label>
                </div>
                <div className={`fcc`}>
                    <input type="checkbox" id="checkbox2" checked={!prodChecked} onChange={handleCheck} />
                    <label htmlFor="checkbox2">Dev</label>
                </div>
                <div className="fcc w-fit px-2 py-1 rounded-md shadow-md border cursor-pointer active:bg-gray-200 hover:bg-gray-100" onClick={() => window.location.reload()}>
                    Reload
                </div>
            </div>
            <div className="ml-20 p-4 flex flex-row gap-6 justify-center flex-wrap">
                {Object.keys(paths).map((path, index) => (
                    <ApiBlock key={index} title={path} />
                ))}
            </div> */}
            {userData.length > 0 &&
                <div className="font-bold flex flex-col gap-4 items-center">
                    <div className=" p-3 rounded-lg bg-transparent text-spotGrey border shadow-lg z-50 backdrop-blur-md z-100 w-fit">
                        <h1 className="text-3xl text-center">
                            Total Users: {userData.length}
                        </h1>
                    </div>
                    <div className=" p-3 rounded-lg bg-transparent text-spotGrey border shadow-lg z-50 backdrop-blur-md z-100 w-fit">
                        <h1 className="text-3xl text-center">
                            Lot Requests: {pinData.length}
                        </h1>
                    </div>
                </div>
            }
        </div>
    )
}

export default Dashboard;