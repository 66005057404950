import React, { useState, useEffect } from 'react';
import AWS from '../../../aws-config';
import { Line } from 'react-chartjs-2';
import 'chart.js/auto';
import 'chartjs-adapter-date-fns';

const Billing = () => {
    const [costData, setCostData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [timePeriod, setTimePeriod] = useState('monthToDate');
    const [selectedService, setSelectedService] = useState('');
    const [isAuthenticated, setIsAuthenticated] = useState(sessionStorage.getItem('authenticated') === 'true');
    const [password, setPassword] = useState('');

    const fetchData = async (period) => {
        try {
            const costExplorer = new AWS.CostExplorer();
            let startDate, endDate;

            const today = new Date();
            const year = today.getFullYear();
            const month = today.getMonth() + 1;
            const day = today.getDate();

            switch (period) {
                case 'monthToDate':
                    startDate = `${year}-${month < 10 ? '0' + month : month}-01`;
                    endDate = `${year}-${month < 10 ? '0' + month : month}-${day < 10 ? '0' + day : day}`;
                    break;
                case 'quarterToDate':
                    const quarterStartMonth = Math.floor((month - 1) / 3) * 3 + 1;
                    startDate = `${year}-${quarterStartMonth < 10 ? '0' + quarterStartMonth : quarterStartMonth}-01`;
                    endDate = `${year}-${month < 10 ? '0' + month : month}-${day < 10 ? '0' + day : day}`;
                    break;
                case 'lastQuarter':
                    const lastQuarterEndMonth = Math.floor((month - 1) / 3) * 3;
                    const lastQuarterStartMonth = lastQuarterEndMonth - 2;
                    startDate = `${year}-${lastQuarterStartMonth < 10 ? '0' + lastQuarterStartMonth : lastQuarterStartMonth}-01`;
                    endDate = `${year}-${lastQuarterEndMonth < 10 ? '0' + lastQuarterEndMonth : lastQuarterEndMonth}-30`;
                    break;
                case 'last6Months':
                    const sixMonthsAgo = new Date(today.setMonth(today.getMonth() - 6));
                    startDate = `${sixMonthsAgo.getFullYear()}-${sixMonthsAgo.getMonth() + 1 < 10 ? '0' + (sixMonthsAgo.getMonth() + 1) : sixMonthsAgo.getMonth() + 1}-01`;
                    endDate = `${year}-${month < 10 ? '0' + month : month}-${day < 10 ? '0' + day : day}`;
                    break;
                case 'last12Months':
                    const twelveMonthsAgo = new Date(today.setMonth(today.getMonth() - 12));
                    startDate = `${twelveMonthsAgo.getFullYear()}-${twelveMonthsAgo.getMonth() + 1 < 10 ? '0' + (twelveMonthsAgo.getMonth() + 1) : twelveMonthsAgo.getMonth() + 1}-01`;
                    endDate = `${year}-${month < 10 ? '0' + month : month}-${day < 10 ? '0' + day : day}`;
                    break;
                default:
                    startDate = `${year}-${month < 10 ? '0' + month : month}-01`;
                    endDate = `${year}-${month < 10 ? '0' + month : month}-${day < 10 ? '0' + day : day}`;
            }

            const params = {
                TimePeriod: {
                    Start: startDate,
                    End: endDate,
                },
                Granularity: 'DAILY',
                Metrics: ['UnblendedCost'],
                GroupBy: [
                    {
                        Type: 'DIMENSION',
                        Key: 'SERVICE',
                    },
                ],
            };

            const data = await costExplorer.getCostAndUsage(params).promise();

            const formattedData = data.ResultsByTime.map(item => ({
                date: new Date(item.TimePeriod.Start),
                services: item.Groups.map(group => ({
                    service: group.Keys[0],
                    cost: group.Metrics.UnblendedCost.Amount,
                })),
            }));

            setCostData(formattedData);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching cost data:', error);
            setLoading(false);
        }
    };

    useEffect(() => {
        if (isAuthenticated) {
            fetchData(timePeriod);
        }
    }, [timePeriod, isAuthenticated]);

    const handleTimePeriodChange = (event) => {
        setTimePeriod(event.target.value);
        setLoading(true);
    };

    const handleServiceChange = (event) => {
        setSelectedService(event.target.value);
    };

    const handlePasswordSubmit = () => {
        if (password.toString().toLowerCase() === 'password') {
            sessionStorage.setItem('authenticated', 'true');
            setIsAuthenticated(true);
        } else {
            alert('Incorrect password');
        }
    };

    const services = [...new Set(costData.flatMap(item => item.services.map(service => service.service)))];

    const data = {
        labels: costData.map(item => item.date),
        datasets: selectedService ? [{
            label: selectedService,
            data: costData.map(item => item.services.find(service => service.service === selectedService)?.cost || 0),
            fill: false,
            backgroundColor: 'rgba(75,192,192,0.4)',
            borderColor: 'rgba(75,192,192,1)',
        }] : [{
            label: 'All Services',
            data: costData.map(item => item.services.reduce((total, service) => total + parseFloat(service.cost), 0)),
            fill: false,
            backgroundColor: 'rgba(255,227,116,0.4)',
            borderColor: 'rgba(255,227,116,1)',
        }],
    };

    const options = {
        scales: {
            x: {
                type: 'time',
                time: {
                    unit: 'day',
                    tooltipFormat: 'MMM dd, yyyy',
                },
            },
            y: {
                beginAtZero: true,
            },
        },
    };

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            handlePasswordSubmit();
        }
    };

    const billing = 'https://us-east-1.console.aws.amazon.com/costmanagement/home?region=us-east-1#/home';

    return (
        <div className='ml-20 p-8'>
            {isAuthenticated ? (
                <>
                    <h1 className='text-3xl font-bold'>AWS Billing Dashboard</h1>
                    <div className='flex flex-row items-center'>
                        <div className='mb-4'>
                            <label className='mr-2' htmlFor='timePeriod'>Select Time Period: </label>
                            <select id='timePeriod' value={timePeriod} onChange={handleTimePeriodChange}>
                                <option value='monthToDate'>Month to Date</option>
                                <option value='quarterToDate'>Quarter to Date</option>
                                <option value='lastQuarter'>Last Quarter</option>
                                <option value='last6Months'>Last 6 Months</option>
                                <option value='last12Months'>Last 12 Months</option>
                            </select >
                        </div >
                        <div className='mb-4'>
                            <label className='mr-2' htmlFor='service'>Select Service: </label>
                            <select id='service' value={selectedService} onChange={handleServiceChange}>
                                <option value=''>All Services</option>
                                {services.map(service => (
                                    <option key={service} value={service}>{service}</option>
                                ))}
                            </select>
                        </div >
                        <a href={billing} target='_blank' rel='noreferrer noopener' className='px-4 h-10 flex justify-center items-center bg-white border rounded-lg shadow-md hover:bg-gray-200'>View in AWS Console</a>
                    </div>
                    {
                        loading ? (
                            <p> Loading...</p >
                        ) : (
                            <Line data={data} options={options} />
                        )}
                </>
            ) : (
                <div className='w-full h-full flex flex-col justify-center items-center'>
                    <div className='border shadow-lg rounded-xl p-8 flex flex-col justify-center items-center'>
                        <h2 className='w-full text-center'>Please enter the password to access the billing data</h2>
                        <input
                            type='password'
                            value={password}
                            className='border p-2 rounded-lg w-full'
                            onChange={(e) => setPassword(e.target.value)}
                            onKeyDown={handleKeyPress}
                        />
                        <button className='px-4 py-2 mt-6 bg-spotYellow rounded-lg shadow-md' onClick={handlePasswordSubmit}>Submit</button>
                    </div>
                </div>
            )
            }
        </div >
    );
};

export default Billing;