// This page is just for the map component. It pulls data from MapBox (open source map API) and displays the map on the screen.
// Each polygon is a parking spot. The color of the polygon is determined by the violation type.
// The map is interactive and can be zoomed in and out of.
// The map has default settings that can be changed by passing in props such as zoom and coordinates.

import React, { useEffect, useState } from "react";
//import RefreshButton from "./RefreshButton";
import mapboxgl from "mapbox-gl";
import { fetchAllData } from "../../utility/fetcher";
import 'mapbox-gl/dist/mapbox-gl.css';
import "./Map.css";

// Mapbox Tokens
mapboxgl.accessToken = "pk.eyJ1IjoicnlhbmhhZ2VydHkzMiIsImEiOiJjbHYyd25vdGEwbXhoMmtwN3hkZGZ6ZmxmIn0.8j4S80-LC8wX7mLHwWrc_g";
// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

// Map Creation
const Map = ({
    styling,
    buttons,
}) => {
    const [userData, setUserData] = useState([]);

    useEffect(() => {
        const getData = async () => {
            const paths = {
                "userData": `/general/spotusers`,
            }
            const data = await fetchAllData(paths);
            setUserData(data.userData);
        }

        getData();
    }, []);


    //const navigate = useNavigate();
    const [isMapReady, setIsMapReady] = useState(false);
    const [map, setMap] = useState(null);
    const [isStyleLoaded, setIsStyleLoaded] = useState(false);

    navigator.permissions.query({ name: "geolocation" })
        .then((result) => {
            if (result.state === "denied") {
                // Reprompt for geolocation permissions
                navigator.geolocation.getCurrentPosition(
                    (position) => {
                        // Success callback
                        console.log("Geolocation permissions granted");
                        // Do something with the position data
                    },
                    (error) => {
                        // Error callback
                        console.error("Geolocation permissions denied");
                    }
                );
            }
        })
        .catch((error) => {
            console.error(error);
        });

    if (navigator.geolocation) {
        navigator.permissions
            .query({ name: "geolocation" })
            .then(function (result) {
                if (result.state === "granted") {
                    //If granted then you can directly call your function here
                } else if (result.state === "prompt") {
                    navigator.geolocation.getCurrentPosition(
                        function (position) {
                            // Code to execute when geolocation is successfully obtained
                            const { latitude, longitude } = position.coords;
                            console.log("Latitude:", latitude);
                            console.log("Longitude:", longitude);
                        },
                        function (error) {
                            console.error(error);
                        }
                    );
                } else if (result.state === "denied") {
                    //If denied then you have to show instructions to enable location
                }
                result.onchange = function () {
                    console.log(result.state);
                };
            });
    } else {
        alert("Sorry Not available!");
    }

    // Default settings
    const startSpotCoordinates = [-111.64736, 40.24778] //startSpot ? startSpot : uniCoordinates;
    const mapZoom = 17 //zoom ? zoom : defaultZoom;
    const darkMode = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
    const mapCss = styling ? styling : { // Default styling
        flex: "1",
        top: "0%",
        right: "0px",
        // position: "relative",
        touchAction: "none",
        width: "100vw",
        height: "105vh",
        justifyContent: "center",
        alignItems: "center",
        position: "fixed",
    };

    // Color Scheme
    if (darkMode === true) {
        var colorScheme = "mapbox://styles/mapbox/satellite-streets-v11?"
    } else {
        colorScheme = "mapbox://styles/mapbox/satellite-streets-v11?"
    }

    // Map Object
    useEffect(() => {
        // Create map
        const newMap = new mapboxgl.Map({
            container: "map-container",
            style: colorScheme,
            center: startSpotCoordinates,
            zoom: mapZoom
        });

        // Add geolocate control to the map.
        newMap.addControl(
            new mapboxgl.GeolocateControl({
                positionOptions: {
                    enableHighAccuracy: true
                },
                trackUserLocation: true,
                showUserHeading: true
            })
        );

        // Create map
        setMap(newMap);
        setIsMapReady(true);

        // Add features
        newMap.on("load", async () => {
            setIsStyleLoaded(true);
            // Add icons here
        });

        return () => {
            newMap.remove();
        };
        // eslint-disable-next-line
    }, []);

    const addIcons = (pinData) => {
        if (map && isStyleLoaded) {
            const coordinatesArray = [];
            for (let i in pinData) {
                coordinatesArray.push(pinData[i].pinCoordinates);
            }

            pinData.forEach((pin) => {
                const popup = new mapboxgl.Popup({ offset: 25 }).setText(
                    `User: ${pin.userName}`
                );

                new mapboxgl.Marker({ color: '#ffe374' })
                    .setLngLat(JSON.parse(pin.pinCoordinates))
                    .setPopup(popup) // sets a popup on this marker
                    .addTo(map);
            });
        }
    }

    const pinData = [];
    if (userData.length > 0) {
        userData.forEach((user) => {
            if (user.lotRequests) {
                for (let i in user.lotRequests) {
                    pinData.push({
                        pinCoordinates: user.lotRequests[i].coordinates,
                        userName: user.settings.personalInfo.firstName + " " + user.settings.personalInfo.lastName,
                    })
                }
            }
        });
    }

    useEffect(() => {
        if (userData.length > 0) {
            addIcons(pinData);
        }
        // eslint-disable-next-line
    }, [userData]);

    // Display map with or without buttons
    if (buttons === false) {
        return (
            <div id="map-container" style={mapCss}>
                {!isMapReady && (
                    <div className="loading-container">
                        <div className="loading-spinner"></div>
                    </div>
                )}
                {userData.length > 0 &&
                    <div className="absolute bottom-8 font-bold right-8 p-10 bg-white z-100">
                        <h1>
                            Total Request Count: {pinData.length}
                        </h1>
                    </div>
                }
            </div>
        );
    } else {
        return (
            <div id="map-container" style={mapCss}>
                {!isMapReady && (
                    <div className="loading-container">
                        <div className="loading-spinner"></div>
                    </div>
                )}

                {userData.length > 0 &&
                    <div className="absolute bottom-24 font-bold right-8 flex flex-row gap-4">
                        <div className=" p-3 rounded-lg bg-transparent text-white shadow-lg z-50 backdrop-blur-md z-100">
                            <h1 className="text-3xl">
                                Total Count: {pinData.length}
                            </h1>
                        </div>
                        <div className=" p-3 rounded-lg bg-transparent text-white shadow-lg z-50 backdrop-blur-md z-100">
                            <h1 className="text-3xl">
                                Total Users: {userData.length}
                            </h1>
                        </div>
                    </div>
                }
            </div>
        );
    }
};

export default Map;
