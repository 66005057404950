import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import yaml from "yaml";

// Sample YAML content as a placeholder
const initialYaml = `
FRAME_SKIP: 5
FRAMES_TO_SAVE: 5
PERC_MOTION_THRESH: 0.003
RECORDING_LENGTH: 10
OVERLAP_SEC: 1
RECORDING_FPS: 5
BLUR_MASK_BEFORE_SENDING: false
VID_BLUR_SIGMA: 30
MOTION_BLUR_SIGMA: 15
MOTION_MASK_THRESH: 0.05
MASK_REDUCTION: 8
MASK_KERNEL_SIZE: 9
MASK_SIGMA: 2
PIXEL_DIFF_THRESH: 25
CLEANUP_AGE: 5
CLEANUP_MIN: 1
SEC_BETWEEN_SNAPSHOTS: 60
DATE_FMT: "%Y-%m-%d-%H-%M-%S"
USE_TCP: true
SENTRY_SETTINGS_UPDATE_MIN: 15
`;

const GeneralSentrySettings = () => {
    const [yamlData, setYamlData] = useState({});
    const [message, setMessage] = useState({});

    // Parse initial YAML on load
    useEffect(() => {
        const parsed = yaml.parse(initialYaml);
        setYamlData(parsed);
    }, []);

    // Update state when editing
    const handleChange = (key, value) => {
        setYamlData((prev) => ({
            ...prev,
            [key]: value,
        }));
    };

    // Save YAML back to string format
    const handleSave = () => {
        const newYaml = yaml.stringify(yamlData);
        console.log(newYaml);
        setMessage({ type: "success", message: "Settings saved successfully!" });

        if (message.type === "success") {
            setTimeout(() => {
                setMessage({});
            }, 3000);
        }
    };

    const sentries = {
        "org1": [
            'sentry1',
            'sentry2',
            'sentry3'
        ],
        "org2": [
            'sentry4',
            'sentry5',
            'sentry6'
        ],
    }

    return (
        <div className="p-6 ml-20 font-sans flex items-center justify-center flex-col">
            <div className="flex flex-row gap-10">

                {/* GLOBAL SENTRY SETTINGS */}
                <div className="flex flex-col">
                    <h1 className="text-3xl font-bold mb-4 w-full text-center">Global Settings</h1>
                    <div className="flex flex-row gap-10">
                        <div className="flex flex-col gap-10">
                            <div className="space-y-4 border p-6 rounded-lg shadow-md">
                                <h2 className="text-xl font-semibold mb-2">Boolean Settings</h2>
                                {Object.entries(yamlData).map(([key, value]) => (
                                    typeof value === "boolean" && (
                                        <div key={key} className="space-y-2">
                                            <label className="block font-semibold">
                                                {key}:
                                            </label>
                                            <select
                                                value={value ? "true" : "false"}
                                                onChange={(e) => handleChange(key, e.target.value === "true")}
                                                className="block w-52 p-2 border rounded-md"
                                            >
                                                <option value="true">true</option>
                                                <option value="false">false</option>
                                            </select>
                                        </div>
                                    )
                                ))}
                            </div>
                            <div className="space-y-4 border p-6 rounded-lg shadow-md">
                                <h2 className="text-xl font-semibold mb-2">Time Related Settings</h2>
                                {Object.entries(yamlData).map(([key, value]) => (
                                    ["RECORDING_LENGTH", "OVERLAP_SEC", "SEC_BETWEEN_SNAPSHOTS", "SENTRY_SETTINGS_UPDATE_MIN", "DATE_FMT"].includes(key) && (
                                        <div key={key} className="space-y-2">
                                            <label className="block font-semibold">
                                                {key}:
                                            </label>
                                            <input
                                                type={typeof value === "number" ? "number" : "text"}
                                                value={value}
                                                onChange={(e) =>
                                                    handleChange(key, typeof value === "number" ? +e.target.value : e.target.value)
                                                }
                                                className="block p-2 border rounded-md w-full outline-spotYellow"
                                            />
                                        </div>
                                    )
                                ))}
                            </div>
                        </div>
                        <div className="space-y-4 border p-6 rounded-lg shadow-md">
                            <h2 className="text-xl font-semibold mb-2">Blur, Masks, Frames, and Recording Settings</h2>
                            {Object.entries(yamlData).map(([key, value]) => (
                                ["FRAME_SKIP", "FRAMES_TO_SAVE", "PERC_MOTION_THRESH", "RECORDING_FPS", "VID_BLUR_SIGMA", "MOTION_BLUR_SIGMA", "MOTION_MASK_THRESH", "MASK_REDUCTION", "MASK_KERNEL_SIZE", "MASK_SIGMA", "PIXEL_DIFF_THRESH", "CLEANUP_AGE", "CLEANUP_MIN"].includes(key) && (
                                    <div key={key} className="space-y-2">
                                        <label className="block font-semibold">
                                            {key}:
                                        </label>
                                        <input
                                            type={typeof value === "number" ? "number" : "text"}
                                            value={value}
                                            onChange={(e) =>
                                                handleChange(key, typeof value === "number" ? +e.target.value : e.target.value)
                                            }
                                            className="block w-full p-2 border rounded-md outline-spotYellow"
                                        />
                                    </div>
                                )
                            ))}
                        </div>
                    </div>
                    <button
                        onClick={handleSave}
                        className="mt-6 px-4 py-2 bg-gray-300 text-spotGrey shadow-sm font-semibold rounded-md hover:shadow-lg transition-all duration-200 ease-in-out hover:bg-spotYellow"
                    >
                        Save Settings
                    </button>
                    {Object.keys(message).lengh > 0 && <p className={`mt-4 text-sm text-red-500`}>{message.message}</p>}
                </div>

                {/* VERTICAL LINE */}
                <div className="w-1 border-l"></div>

                {/* INDIVIDUAL SENTRIES */}
                <div className="flex flex-col">
                    <h1 className="text-3xl font-bold mb-4 w-full text-center">Individual Sentries</h1>
                    <div className="flex flex-col gap-5">
                        {Object.entries(sentries).map(([org, sentryList]) => (
                            <div key={org} className="border p-6 rounded-lg shadow-md">
                                <h2 className="text-xl font-semibold mb-2">{org}</h2>
                                <div className="flex flex-col gap-4">
                                    {sentryList.map((sentry, index) => (
                                        <Link to={`/sentries/${sentry}`}
                                            onClick={handleSave}
                                            className="px-4 py-2 bg-gray-300 text-spotGrey shadow-sm font-semibold rounded-md hover:shadow-lg transition-all duration-50 ease-in-out hover:bg-spotYellow"
                                        >
                                            {sentry}
                                        </Link>
                                    ))}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default GeneralSentrySettings;