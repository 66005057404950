import React, { useState, useEffect } from "react";
import yaml from "yaml";
import file from "./ihub_south_entrance.yaml";
import { Link } from "react-router-dom";
import { FaArrowLeft } from "react-icons/fa6";
import { fetchAllData } from "../../utility/fetcher";
import { copyToClipboard } from "../../utility/copyToClipboard";
import { LuCopy } from "react-icons/lu";
import { Toaster } from "sonner";
const API_KEY = process.env.REACT_APP_API_KEY;

const SingleCamSettings = () => {
    const [yamlData, setYamlData] = useState({});
    const [updatedYaml, setUpdatedYaml] = useState("");

    useEffect(() => {
        const loadYamlFile = async () => {
            try {
                // Fetch the raw YAML file
                const response = await fetch(file);
                const yamlContent = await response.text();

                // Parse YAML into a JavaScript object
                const parsedYaml = yaml.parse(yamlContent);

                setYamlData(parsedYaml);
            } catch (error) {
                console.error("Failed to load or parse YAML file:", error);
            }
        };

        loadYamlFile();

    }, []);

    useEffect(() => {
        const fetchData = async () => {
            const response = await fetchAllData({ "file": "/general/getSentrySettings?name=ihub_south_entrance.yaml" })
            const cdnLink = response.file.CDN_URL;

            try {
                console.log(`\x1b[32mGetting file from: ${cdnLink}\x1b[0m`);
                const response = await fetch(cdnLink, {
                    method: "GET",
                    headers: {
                        'x-api-key': API_KEY
                    }
                });

                const text = await response.text();
                const data = yaml.parse(text);
                console.log(data);
            } catch (error) {
                console.error("Error fetching the file:", error);
            }
        };

        fetchData();
    }, []);

    const handleChange = (path, value) => {
        const updateNestedValue = (obj, pathArray, value) => {
            if (pathArray.length === 1) {
                obj[pathArray[0]] = value;
            } else {
                updateNestedValue(obj[pathArray[0]], pathArray.slice(1), value);
            }
        };

        setYamlData((prev) => {
            const newData = { ...prev };
            updateNestedValue(newData, path.split("."), value);
            return newData;
        });
    };

    const handleSave = () => {
        const newYaml = yaml.stringify(yamlData);
        setUpdatedYaml(newYaml);
        alert("YAML settings saved successfully!");
        console.log(newYaml);
    };

    const CameraDropdown = ({ camera, index, handleChange }) => {
        const [isOpen, setIsOpen] = useState(false);

        return (
            <div className="border p-4 rounded-md mb-4 hover:shadow-lg transition-all duration-200 ease-in-out">
                <div
                    className="flex flex-row items-center gap-4"
                >
                    <p className="font-bold cursor-pointer select-none" onClick={() => setIsOpen(!isOpen)}>Camera {index + 1}: {camera.name}</p>
                    <div className="p-1" onClick={() => copyToClipboard(camera.name)}>
                        <LuCopy size={20} className="text-gray-500" />
                    </div>
                </div>
                {isOpen && (
                    <div className="mt-2 flex flex-col gap-4">
                        {Object.keys(camera).map((key) =>
                            key === "selection_points" ? (
                                <div key={key} className="space-y-2">
                                    <label className="block font-semibold">{key}:</label>
                                    <textarea
                                        value={camera[key].map((point) => point.join(", ")).join("\n")}
                                        onChange={(e) =>
                                            handleChange(
                                                `cameras.${index}.selection_points`,
                                                e.target.value
                                                    .split("\n")
                                                    .map((line) => line.split(",").map(Number))
                                            )
                                        }
                                        className="block w-full min-h-36 p-2 border rounded-md outline-spotYellow"
                                        rows={4}
                                    ></textarea>
                                </div>
                            ) : (
                                <div key={key} className="space-y-2">
                                    <label className="block font-semibold">{key}:</label>
                                    <input
                                        type={typeof camera[key] === "number" ? "number" : "text"}
                                        value={camera[key]}
                                        onChange={(e) =>
                                            handleChange(
                                                `cameras.${index}.${key}`,
                                                typeof camera[key] === "number"
                                                    ? +e.target.value
                                                    : e.target.value
                                            )
                                        }
                                        className="block p-2 border rounded-md outline-spotYellow w-full"
                                    />
                                </div>
                            )
                        )}
                    </div>
                )}
            </div>
        );
    };

    return (
        <div className="p-6 font-sans ml-20">
            <Link to={"/settings/sentries"} className="fcc bg-white shadow-lg rounded-full p-4 justify-center items-center gap-1 text-2xl text-spotGray mt-6 ml-6 dark:text-gray-300 z-50 fixed top-0 left-20 border"><FaArrowLeft /></Link>
            <h1 className="text-2xl font-bold mb-4 mt-20">Single Sentry Settings</h1>
            <div className="space-y-4">
                {/* Top-level fields */}
                <div className="border p-6 rounded-lg shadow-md">
                    <h2 className="text-lg font-bold">🫡 General:</h2>
                    {["sentry_name", "sentry_type", "organization", "organizationID"].map((key) => (
                        <div key={key} className="space-y-2">
                            <label className="block font-semibold">{key}:</label>
                            <input
                                type="text"
                                value={yamlData[key] || ""}
                                onChange={(e) => handleChange(key, e.target.value)}
                                className="block w-52 p-2 border rounded-md outline-spotYellow"
                            />
                        </div>
                    ))}
                </div>
                {/* Cameras */}
                <div className="pb-5 border p-6 rounded-lg shadow-md">
                    <h2 className="text-lg font-bold">📷 Cameras:</h2>
                    {yamlData.cameras?.map((camera, index) => (
                        <CameraDropdown
                            key={index}
                            camera={camera}
                            index={index}
                            handleChange={handleChange}
                        />
                    ))}
                </div>

                {/* Global settings */}
                <div className="space-y-4 pt-5 border p-6 rounded-lg shadow-md">
                    <h2 className="text-lg font-bold">🌍️ Global Settings:</h2>
                    {["SENTRY_SETTINGS_UPDATE_MIN", "CLEANUP_AGE", "CLEANUP_MIN"].map((key) => (
                        <div key={key} className="space-y-2">
                            <label className="block font-semibold">{key}:</label>
                            <input
                                type="number"
                                value={yamlData[key] || ""}
                                onChange={(e) => handleChange(key, +e.target.value)}
                                className="block w-52 p-2 border rounded-md outline-spotYellow"
                            />
                        </div>
                    ))}
                    <h2 className="text-lg font-bold">Off Periods:</h2>
                    {yamlData.OFF_PERIODS?.map((period, index) => (
                        <div key={index} className="space-y-2">
                            <label className="block font-semibold">Start:</label>
                            <input
                                type="time"
                                value={period.start || ""}
                                onChange={(e) => handleChange(`OFF_PERIODS.${index}.start`, e.target.value)}
                                className="block w-52 p-2 border rounded-md outline-spotYellow"
                            />
                            <label className="block font-semibold">End:</label>
                            <input
                                type="time"
                                value={period.end || ""}
                                onChange={(e) => handleChange(`OFF_PERIODS.${index}.end`, e.target.value)}
                                className="block w-52 p-2 border rounded-md outline-spotYellow"
                            />
                        </div>
                    ))}
                </div>
            </div>
            <button
                onClick={handleSave}
                className="mt-6 px-4 py-2 bg-gray-300 text-spotGrey shadow-sm font-semibold rounded-md hover:shadow-lg transition-all duration-200 ease-in-out hover:bg-spotYellow"
            >
                Save Settings
            </button>
            {updatedYaml && (
                <div className="mt-6">
                    <h3 className="text-lg font-bold mb-2">Updated YAML:</h3>
                    <pre className="bg-gray-100 p-4 rounded-md">{updatedYaml}</pre>
                </div>
            )}
            <Toaster />
        </div>
    );
};

export default SingleCamSettings;