import React, { useState, useEffect } from 'react';
import { Toaster, toast } from 'sonner';
import "./directory.css"
import { fetchAllData, postData } from '../../utility/fetcher';

export const UniPage = () => {
    const [editedData, setEditedData] = useState({});

    useEffect(() => {
        const dataFromStorage = JSON.parse(sessionStorage.getItem('editData'));
        if (dataFromStorage) setEditedData(dataFromStorage);

        getBeamerData(dataFromStorage["orgID"]);
    }, []);

    const handleInputChange = (e) => {
        setEditedData({
            ...editedData,
            [e.target.name]: e.target.value
        });
    };

    const handleSave = () => {
        const listData = JSON.parse(sessionStorage.getItem("violationHistory"));
        const plate = sessionStorage.getItem("currentPlate");

        // Not empty list
        const notEmptyList = [
            "orgName",
            "apiURL",
            "apiKey",
            "defaultMapZoom",
            "orgCoordinates",
            "violationTimeGoal",
            "baseFee"
        ]

        // Check if any of the not empty fields are empty
        let bool = true;
        while (bool === true) {
            for (let i in notEmptyList) {
                if (editedData[notEmptyList[i]] === "") {
                    toast.error(lableFixer(notEmptyList[i]) + " field cannot be empty.");
                }
            }
            bool = false
        }

        // TODO: Link this to a dynamic link
        const historyUrl = "https://thprlfo0gl.execute-api.us-east-1.amazonaws.com/default/UniversityHistoryAPI"
        const apiKey = process.env.REACT_APP_API_KEY;

        try {
            const saveData = async () => {
                console.log("HISTORY Data to save:", editedData);
                const response = await fetch(historyUrl, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        'x-api-key': apiKey,
                    },
                    body: JSON.stringify(editedData),
                });
                if (response.ok) {
                    console.log("Data saved successfully!");
                    toast.success("Entry saved successfully!");
                } else {
                    console.log("Failed to save data.");
                }
            };

            (async () => {
                await saveData();
                const updatedListData = listData.filter(item => item.LicensePlate !== plate);
                updatedListData.push(editedData);
                sessionStorage.setItem("violationHistory", JSON.stringify(updatedListData));
                window.location.href = "/directory";
            })();
        } catch (error) {
            console.error("Error saving data:", error);
        }
    };

    const handleCancel = () => {
        window.location.href = "/directory";
    };

    const lableFixer = (label) => {
        // Fix label to be more readable
        return label.replace(/([A-Z])/g, ' $1').replace(/^./, function (str) { return str.toUpperCase(); });
    };

    const [ogBeamerData, setOgBeamerData] = useState(null);
    const [beamerTableData, setBeamerTableData] = useState({});

    const handleBeamerInputChange = ({ beamer, camera, inputType, input }) => {
        console.log(beamer, camera, inputType, input);
        console.log(beamerTableData)
        const updatedBeamerData = {
            ...beamerTableData,
            [beamer]: {
                ...beamerTableData[beamer],
                cameras: beamerTableData[beamer].cameras.map(cam =>
                    cam.name === camera ? { ...cam, [inputType]: input } : cam
                )
            }
        };

        setBeamerTableData(prevData => ({
            ...prevData,
            ...updatedBeamerData
        }));
    };

    const handleBeamerSave = async () => {
        const ogData = ogBeamerData;
        ogData.beamers = beamerTableData;
        const newData = ogData;

        console.log("data to save:", newData);

        const toastId = toast.loading("Saving data...");

        try {
            const beamerPath = "/general/beamBridge"
            const response = await postData(beamerPath, newData);
            toast.success("Data saved successfully!", { id: toastId });
            console.log(response.message)
        } catch (error) {
            console.error("Error saving data:", error);
            toast.error("Failed to save data.", { id: toast });
        }
    };

    const getBeamerData = async (orgID) => {
        const path = { "beamerData": `/general/beamBridge?orgID=${orgID}` }
        const data = await fetchAllData(path);
        const beamerData = data.beamerData;
        setOgBeamerData(beamerData);
        const beamers = beamerData.beamers;
        setBeamerTableData(beamers);
    }

    return (
        <div>
            <div className='w-screen pl-20'>
                <div className="flex flex-col gap-12 lg:frc lg:gap-0">
                    <div className="fcc gap-4 w-[90%]">
                        <h1 className='font-bold w-full text-center text-3xl p-8'>University Settings</h1>
                        <div className='w-full px-20'>
                            <label htmlFor="timeIn" className='font-bold'>{lableFixer("orgName")}</label>
                            <div>
                                <input
                                    type='text'
                                    name="orgName"
                                    id="orgName"
                                    className='border py-1 w-full pl-2 mt-2 border-gray-300 rounded-md'
                                    value={editedData["orgName"] || ""}
                                    onChange={handleInputChange}
                                />
                            </div>
                        </div>
                        <div className='w-full px-20'>
                            <label htmlFor="defaultMapZoom" className='editor-label'>{lableFixer("defaultMapZoom")}</label>
                            <br />
                            <input
                                type='text'
                                name="defaultMapZoom"
                                id="defaultMapZoom"
                                className='border py-1 w-full pl-2 mt-2 border-gray-300 rounded-md'
                                value={editedData["defaultMapZoom"] || ""}
                                onChange={handleInputChange}
                            />
                        </div>
                        <div className='w-full px-20'>
                            <label htmlFor="uniCoordinates" className='editor-label'>{lableFixer("orgCoordinates")}</label>
                            <br />
                            <input
                                type='text'
                                name="orgCoordinates"
                                id="orgCoordinates"
                                className='border py-1 w-full pl-2 mt-2 border-gray-300 rounded-md'
                                value={editedData["orgCoordinates"] || ""}
                                onChange={handleInputChange}
                            />
                        </div>
                        <div className='w-full px-20'>
                            <label htmlFor="violationTimeGoal" className='editor-label'>{lableFixer("violationTimeGoal")}</label>
                            <br />
                            <input
                                type='text'
                                name="violationTimeGoal"
                                id="violationTimeGoal"
                                className='border py-1 w-full pl-2 mt-2 border-gray-300 rounded-md'
                                value={editedData["violationTimeGoal"] || ""}
                                onChange={handleInputChange}
                            />
                        </div>
                        <div className='w-full px-20'>
                            <label htmlFor="baseFee" className='editor-label'>{lableFixer("baseFee")}</label>
                            <br />
                            <input
                                type='text'
                                name="baseFee"
                                id="baseFee"
                                className='border py-1 w-full pl-2 mt-2 border-gray-300 rounded-md'
                                value={editedData["baseFee"] || ""}
                                onChange={handleInputChange}
                            />
                        </div>
                        <div className='w-full px-20'>
                            <label htmlFor="violationTypes" className='editor-label'>{lableFixer("violationTypes")}</label>
                            <br />
                            <input
                                type='text'
                                name="violationTypes"
                                id="violationTypes"
                                className='border py-1 w-full pl-2 mt-2 border-gray-300 rounded-md'
                                value={editedData["violationTypes"] || ""}
                                onChange={handleInputChange}
                            />
                        </div>
                        <div className='w-full px-20'>
                            <label htmlFor="lots" className='editor-label'>{lableFixer("lots")}</label>
                            <br />
                            <input
                                type='text'
                                name="lots"
                                id="lots"
                                className='border py-1 w-full pl-2 mt-2 border-gray-300 rounded-md'
                                value={editedData["lots"] || ""}
                                onChange={handleInputChange}
                            />
                        </div>
                        <div className='editor-button-container'>
                            <button className='editor-cancel-button' onClick={handleCancel}>Cancel</button>
                            <button className='editor-save-button' onClick={handleSave}>Save</button>
                        </div>
                        {/* <div className="button-tester">
                        {data ?
                            <div>
                                <button onClick={() => downloadData(data)}>Download Data</button>
                            </div>
                            : null}
                        <button style={{ cursor: "pointer" }} onClick={() => MakeRequest(editedData["orgName"])}>Run Lambda</button>
                    </div> */}
                    </div>
                    <div className="fcc w-[90%]">
                        <h1 className='font-bold w-full text-center text-3xl pb-4'>Beamer Settings</h1>
                        {beamerTableData !== null ? Object.keys(beamerTableData).map((key, index) => (
                            <div className='flex flex-col mb-6 w-[90%]' key={index}>
                                <h1 className='text-xl font-semibold my-2'>Beamer: {key}</h1>
                                <div className="flex flex-col gap-4 ml-8">
                                    {beamerTableData[key].cameras.map((camera, camIndex) => (
                                        <details key={camIndex}>
                                            <summary className='cursor-pointer'>{camera.name}</summary>
                                            <div className='flex flex-col gap-4 ml-8'>
                                                <h2>Camera Name:
                                                    <input
                                                        type='text'
                                                        name={`cameraName-${key}-${camIndex}`}
                                                        className='border py-1 w-full pl-2 mt-2 border-gray-300 rounded-md outline-spotYellow'
                                                        value={beamerTableData[key].cameras[camIndex].name || ""}
                                                        onChange={(e) => handleBeamerInputChange({ beamer: key, camera: beamerTableData[key].cameras[camIndex].name, inputType: 'name', input: e.target.value })}
                                                    />
                                                </h2>
                                                <h3>RTSP Link:
                                                    <input
                                                        type='text'
                                                        name={`rtspLink-${key}-${camIndex}`}
                                                        className='border py-1 w-full pl-2 mt-2 border-gray-300 rounded-md outline-spotYellow'
                                                        value={beamerTableData[key].cameras[camIndex].rtsp_link || ""}
                                                        onChange={(e) => handleBeamerInputChange({ beamer: key, camera: beamerTableData[key].cameras[camIndex].name, inputType: 'rtsp_link', input: e.target.value })}
                                                    />
                                                </h3>
                                                <h3>Resolution:
                                                    <input
                                                        type='text'
                                                        name={`resolution-${key}-${camIndex}`}
                                                        className='border py-1 w-full pl-2 mt-2 border-gray-300 rounded-md outline-spotYellow'
                                                        value={beamerTableData[key].cameras[camIndex].resolution || ""}
                                                        onChange={(e) => handleBeamerInputChange({ beamer: key, camera: beamerTableData[key].cameras[camIndex].name, inputType: 'resolution', input: e.target.value })}
                                                    />
                                                </h3>
                                            </div>
                                        </details>
                                    ))}
                                </div>
                            </div>
                        )) : null}
                        <div className='frc gap-8 mb-10'>
                            <button className='editor-save-button' onClick={handleBeamerSave}>Save</button>
                        </div>
                    </div>
                </div>
                <Toaster richColors />
            </div>
        </div>
    );
}