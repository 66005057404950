import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { BiSolidDashboard } from "react-icons/bi";
import { MdAccountCircle, MdOutlineMap } from "react-icons/md";
import { FaNewspaper } from "react-icons/fa6";
import { IoLogOut } from "react-icons/io5";
import { GoFileDirectoryFill } from "react-icons/go";
import Cookies from 'js-cookie';
import { MdCamera } from "react-icons/md";
import { FaAws } from "react-icons/fa";

const Sidebar = () => {
    const [state, setState] = useState("");
    const currentPath = window.location.pathname;
    const navigate = useNavigate();

    useEffect(() => {
        setState(currentPath);
    }, [currentPath]);

    const SidebarIcon = ({ url, icon, text }) => {
        var isActive = currentPath === url;
        const height = text === "Reports" ? "hover:h-[136px]" : "hover:h-[133px]";

        const colors = {
            "Dashboard": "bg-yellow-200",
            "Directory": "bg-gray-300",
            "Reports": "bg-blue-300",
            "Company Metrics": "bg-blue-300",
            "Billing": "bg-blue-300",
            "Map": "bg-orange-300",
            "Settings": "bg-gray-100",
            "Announcements": "bg-blue-300",
            "Help": "bg-orange-200",
            "Log Out": "bg-red-300",
            "Sentries": "bg-slate-500"
        }

        const hoverColors = {
            "Dashboard": "hover:bg-yellow-100",
            "Directory": "hover:bg-gray-400",
            "Reports": "hover:bg-blue-300",
            "Company Metrics": "hover:bg-blue-300",
            "Billing": "hover:bg-blue-300",
            "Map": "hover:bg-orange-200",
            "Settings": "hover:bg-gray-500",
            "Announcements": "hover:bg-blue-200",
            "Help": "hover:bg-orange-200",
            "Log Out": "hover:bg-red-300",
            "Sentries": "hover:bg-slate-300"
        }

        const folder = {
            "Settings": {
                "My Account": <MdAccountCircle size={20} />,
            },
        }

        const handleLogOut = () => {
            sessionStorage.clear();
            localStorage.clear();
            Cookies.remove("accessToken");
            window.location.href = "/login";
        }

        const handleClick = () => {
            setState(url);
            navigate(url);
        }

        if (text === "Settings") {
            var newUrl = "";
            if (text === "Website") {
                newUrl = "/Website/" + Object.keys(folder[text])[0].toLowerCase().replace(/\s/g, '-');
            } else {
                newUrl = "/Settings/" + Object.keys(folder[text])[0].toLowerCase().replace(/\s/g, '-');
            }

            isActive = currentPath === newUrl;

            return (
                <div onClick={handleClick} className={`relative items-center justify-center rounded-3xl w-16 h-16 mt-2 mb-2 m-auto ${isActive ? `${height}` : `${hoverColors[text]} hover:rounded-[10px] ${height}`}  transition-all duration-200 ease-in-out group ${isActive ? `${colors[text]} text-gray-700 rounded-[10px]` : `bg-gray-300 ${text === "Settings" ? "text-gray-500 hover:text-gray-300 delay-0" : "text-gray-500"}`}`}>
                    <div className={`${isActive ? "flex justify-center align-middle mt-[15px]" : `flex justify-center align-middle mt-[15px]`}`}>
                        {icon}
                    </div>
                    <div className={`group-hover:flex group-hover:flex-col group-hover:justify-center group-hover:align-middle cursor-pointer hidden w-auto p-2 group-hover:ml-[-2px] min-w-max text-s ${text === "Settings" ? "text-gray-300" : "text-gray-500"} font-bold transition-all duration-100 origin-left group-hover:opacity-100  ${isActive ? null : "opacity-0"}`}>
                        {Object.entries(folder[text]).map(([key, value], index) => (
                            <Link to={`/${text}/` + key.toLowerCase().replace(/\s/g, '-')} key={index} className={`py-2 px-4 ${text === "Reports" ? "hover:text-blue-200 hover:bg-gray-600" : "hover:bg-gray-300 hover:text-gray-600"} rounded-md transition-all duration-100 ease-in-out group/sub select-none`}>
                                {value}
                                <div className="absolute w-auto p-2 m-2 min-w-max left-20 rounded-md shadow-md bg-gray-700 text-xs text-white font-bold transition-all duration-100 scale-0 origin-left group-hover/sub:scale-100 select-none" style={{ top: `${50 + (index * 35)}px` }}>
                                    {key}
                                </div>
                            </Link>
                        ))}
                    </div>
                </div>
            );
        } else if (text === "Log Out") {
            return (
                <div onClick={handleLogOut} className={`relative flex items-center justify-center cursor-pointer rounded-3xl w-16 h-16 mt-2 mb-2 mx-auto ${isActive ? null : `${hoverColors[text]} hover:rounded-[10px]`}  transition-all duration-200 ease-in-out group ${isActive ? `${colors[text]} text-gray-700 rounded-[10px]` : "bg-gray-300 text-gray-500"}`}>
                    <div className={`${isActive ? null : `transition-all duration-100 ease-in-out`} select-none`}>
                        {icon}
                    </div>
                    <span className="absolute w-auto p-2 m-2 min-w-max left-20 rounded-md shadow-md bg-gray-700 text-s text-white font-bold transition-all duration-100 scale-0 origin-left group-hover:scale-100 select-none">
                        {text}
                    </span>
                </div>
            );
        } else {
            return (
                <Link to={url} onClick={handleClick} className={`relative flex items-center justify-center cursor-pointer rounded-3xl select-none w-16 h-16 mt-2 mb-2 mx-auto ${isActive ? null : `${hoverColors[text]} hover:rounded-[10px]`}  transition-all duration-200 ease-in-out group ${isActive ? `${colors[text]} ${state === "/sentries" ? "text-white" : "text-gray-700"} rounded-[10px]` : "bg-gray-300 text-gray-500"}`}>
                    <div className={`${isActive ? null : `transition-all duration-100 ease-in-out`}`}>
                        {icon}
                    </div>
                    <span className="absolute w-auto p-2 m-2 min-w-max left-20 rounded-md shadow-md bg-gray-700 text-s text-white font-bold transition-all duration-100 scale-0 origin-left select-none group-hover:scale-100">
                        {text}
                    </span>
                </Link >
            );
        }
    }

    return (
        <div className="flex fixed top-0 left-0 h-screen w-20 m-0 flex-col min-w-20 bg-transparent text-white shadow-xl z-50 backdrop-blur-md select-none">
            <div className='fixed left-2 flex flex-col bg-transparent select-none'>
                <SidebarIcon url={"/"} icon={<BiSolidDashboard size="32" />} text='Dashboard' />
                <SidebarIcon url={"/directory"} icon={<GoFileDirectoryFill size="32" />} text='Directory' />
                <SidebarIcon url={"/billing"} icon={<FaAws size="35" />} text='Billing' />
                <SidebarIcon url={"/map"} icon={<MdOutlineMap size="35" />} text='Map' />
                <SidebarIcon url={"/announcements"} icon={<FaNewspaper size="35" />} text='Announcements' />
                <SidebarIcon url={"/sentries"} icon={<MdCamera size="32" />} text='Sentries' />
                <hr className='flex justify-center align-middle ml-2 w-12 border-1 border-gray-400 mt-2 mb-2'></hr>
            </div>
            <div className='fixed flex align-bottom justify-end bottom-0 left-2'>
                <SidebarIcon url={"/login"} icon={<IoLogOut size="32" />} text="Log Out" />
            </div>
        </div>
    );
}

export default Sidebar;