import React, { useEffect, useState } from "react";
import { deleteData, fetchAllData, patchData, postData } from "../utility/fetcher";
import { HiXMark } from "react-icons/hi2";
import { AiOutlinePlus } from "react-icons/ai";

const Announcements = () => {
    const [announcements, setannouncements] = useState();
    const [modal, setmodal] = useState(false);
    const [title, settitle] = useState("");
    const [iconString, seticonString] = useState("");
    const [subtitle, setsubtitle] = useState("");
    const [text, settext] = useState("");
    const [button1Text, setbutton1Text] = useState("Ok");
    const [button2Text, setbutton2Text] = useState("");
    const [foreignDestination, setforeignDestination] = useState("");
    const [foreignDestinationPath, setforeignDestinationPath] = useState("");
    const [announcementId, setannouncementId] = useState("");
    const [published, setpublished] = useState(false);
    const [errorMessage, seterrorMessage] = useState("");

    const getAnnouncements = async () => {
        const response = await fetchAllData({ "announcements": "/general/spotAnnouncements?announcementId=all" });
        setannouncements(response.announcements);
    }

    useEffect(() => {
        getAnnouncements();
    }, []);

    const sortAnnouncements = (announcements) => {
        return announcements.sort((a, b) => b.announcementId - a.announcementId);
    };

    const handleDelete = async (announcementId) => {
        const response = await deleteData(`/general/spotAnnouncements?announcementId=${parseFloat(announcementId)}`);
        console.log(response);
        if (response.message) {
            getAnnouncements();
        }
    }

    const handleSubmitAnnouncement = async () => {
        if (!title) {
            seterrorMessage("Please enter a title.");
            return;
        }

        console.log(foreignDestination, foreignDestinationPath)
        if (button2Text !== "") {
            if (foreignDestination === "") {
                seterrorMessage("Please enter a foreign destination.");
                return;
            } else if (foreignDestination === "false" && foreignDestinationPath === "") {
                seterrorMessage("Please enter a destination path.");
                return;
            } else if (foreignDestination === "true" && foreignDestinationPath === "") {
                seterrorMessage("Please enter a destination path.");
                return;
            } else if (foreignDestination === "share" && foreignDestinationPath !== "") {
                seterrorMessage("Share destination should not have a path.");
                return;
            }
        }

        const objBuild = {
            announcementId: parseFloat(announcementId),
            title: title,
            iconString: iconString,
            subtitle: subtitle,
            text: text,
            button1Text: button1Text === "" ? "Ok" : button1Text,
            button2Text: button2Text,
            foreignDestination: foreignDestination.toString().toLowerCase(),
            foreignDestinationPath: foreignDestinationPath,
            published: published,
            viewCount: 0
        }

        const response = await postData("/general/spotAnnouncements", objBuild);

        if (response.message) {
            getAnnouncements();
            setmodal(false);
        }

        settitle("");
        seticonString("");
        setsubtitle("");
        settext("");
        setbutton1Text("");
        setbutton2Text("");
        setforeignDestination("");
        setforeignDestinationPath("");
        setannouncementId("");
        setpublished(false);
        seterrorMessage("");
    }


    const AnnouncementItem = (announcement) => {
        announcement = announcement.announcement;

        const [checked, setchecked] = useState(announcement.published === "true" ? true : false);


        const handleToggleStatus = async (checked) => {
            console.log(checked)
            const sendStatus = checked === true ? "true" : "false";
            setchecked(checked);

            const response = await patchData(
                `/general/spotAnnouncements?announcementId=${parseFloat(announcement.announcementId)}`,
                { published: sendStatus }
            );
            console.log(response)

            if (response.message) {
                getAnnouncements();
            }
        }

        return (
            <div key={announcement.announcementId} className="border rounded-xl shadow-lg w-fit p-8 m-4 group relative max-w-96">
                <div className="hidden rounded-full p-1 absolute top-[-12px] right-[-12px] bg-gray-300 group-hover:flex items-center justify-center font-bold active:bg-gray-400 cursor-pointer">
                    <HiXMark size="18" onClick={() => handleDelete(announcement.announcementId)} />
                </div>
                <div className="flex flex-row gap-2 items-center mb-4">
                    <p className="font-bold text-2xl mt-2">Publish: </p>
                    <label className="switch text-xl">
                        <input type="checkbox" className="w-5 h-5" checked={checked} onChange={(e) => handleToggleStatus(!checked)} />
                    </label>
                </div>
                <div className="flex flex-row gap-2">
                    <p className="font-bold">Title: </p>
                    <p>{announcement.title}</p>
                </div>
                <div className="flex flex-row gap-2">
                    <p className="font-bold">Icon String: </p>
                    <p>{announcement.iconString}</p>
                </div>
                <div className="flex flex-row gap-2">
                    <p className="font-bold">Subtitle: </p>
                    <p>{announcement.subtitle}</p>
                </div>
                <div className="flex flex-row gap-2">
                    <p className="font-bold">Text Body: </p>
                    <p>{announcement.text}</p>
                </div>
                <div className="flex flex-row gap-2">
                    <p className="font-bold">Close Button Text: </p>
                    <p>{announcement.button1Text}</p>
                </div>
                <div className="flex flex-row gap-2">
                    <p className="font-bold">Action Button Text: </p>
                    <p>{announcement.button2Text}</p>
                </div>
                <div className="flex flex-row gap-2">
                    <p className="font-bold">Foreign Destination: </p>
                    <p>{announcement.foreignDestination}</p>
                </div>
                <div className="flex flex-row gap-2">
                    <p className="font-bold">Destination Path: </p>
                    <p>{announcement.foreignDestinationPath}</p>
                </div>
                <div className="flex flex-row gap-2">
                    <p className="font-bold">View Count: </p>
                    <p>{announcement.viewCount}</p>
                </div>
            </div>
        )
    }

    return (
        <div className={`ml-20 p-8 flex flex-col items-center h-screen ${modal && "overflow-hidden"}`}>
            <h1 className="font-bold text-3xl p-10">Announcements</h1>
            <div className="w-full flex flex-row flex-wrap justify-center">
                {announcements && sortAnnouncements(announcements).map((announcement) => (
                    <AnnouncementItem announcement={announcement} />
                ))}
            </div>
            <div className="p-4 bg-white rounded-full shadow-lg border fixed bottom-10 right-10 hover:bg-gray-100 active:bg-gray-200 cursor-pointer">
                <AiOutlinePlus size="24" onClick={() => setmodal(true)} />
            </div>

            {/* Modal */}
            {modal && (
                <div className="absolute top-0 bottom-0 right-0 left-0 flex items-center justify-center">
                    <div className="absolute top-0 bottom-0 right-0 left-0 bg-black opacity-10"></div>
                    <div className="flex flex-col gap-4 w-fit h-fit bg-white p-8 rounded-lg shadow-lg z-10 relative">
                        <div className="rounded-full p-1 absolute top-[-12px] right-[-12px] bg-white shadow-md items-center justify-center font-bold active:bg-gray-400 cursor-pointer">
                            <HiXMark size="24" onClick={() => setmodal(false)} />
                        </div>
                        <p className="font-bold text-xl w-full text-center">New Announcement</p>
                        <div className="flex flex-row gap-2 items-center">
                            <p className="font-bold mt-3">Title: </p>
                            <input type="text" className="border rounded p-1 outline-spotYellow" onChange={(e) => (settitle(e.target.value), seterrorMessage(""))} />
                        </div>
                        <div className="flex flex-row gap-2 items-center">
                            <p className="font-bold mt-3">Icon String: </p>
                            <input type="text" className="border rounded p-1 outline-spotYellow" onChange={(e) => seticonString(e.target.value)} />
                        </div>
                        <div className="flex flex-row gap-2 items-center">
                            <p className="font-bold mt-3">Subtitle: </p>
                            <input type="text" className="border rounded p-1 outline-spotYellow" onChange={(e) => setsubtitle(e.target.value)} />
                        </div>
                        <div className="flex flex-row gap-2 items-center">
                            <p className="font-bold mt-3">Text Body: </p>
                            <textarea className="border rounded p-1 outline-spotYellow" onChange={(e) => settext(e.target.value)} />
                        </div>
                        <div className="flex flex-row gap-2 items-center">
                            <p className="font-bold mt-3">Close Button Text: </p>
                            <input type="text" placeholder="Ok" className="border rounded p-1 outline-spotYellow" onChange={(e) => setbutton1Text(e.target.value)} />
                        </div>
                        <div className="flex flex-row gap-2 items-center">
                            <p className="font-bold mt-3">Action Button Text: </p>
                            <input type="text" className="border rounded p-1 outline-spotYellow" onChange={(e) => setbutton2Text(e.target.value)} />
                        </div>
                        <div className="flex flex-row gap-2 items-center">
                            <div className="flex flex-row gap-2 items-center">
                                <p className="font-bold mt-3">Foreign Destination: </p>
                                <div className="flex flex-row gap-2">
                                    <label>
                                        <input type="radio" name="foreignDestination" value="true" className="mr-1 accent-spotGrey" onChange={(e) => setforeignDestination(e.target.value)} />
                                        True
                                    </label>
                                    <label>
                                        <input type="radio" name="foreignDestination" value="false" className="mr-1 accent-spotGrey" onChange={(e) => setforeignDestination(e.target.value)} />
                                        False
                                    </label>
                                    <label>
                                        <input type="radio" name="foreignDestination" value="share" className="mr-1 accent-spotGrey" onChange={(e) => setforeignDestination(e.target.value)} />
                                        Share
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-row gap-2 items-center">
                            <p className="font-bold mt-3">Destination Path: </p>
                            <input type="text" className="border rounded p-1 outline-spotYellow" onChange={(e) => setforeignDestinationPath(e.target.value)} />
                        </div>
                        <div className="flex flex-row gap-2 items-center">
                            <div className="flex flex-row gap-2 items-center">
                                <p className="font-bold mt-3">Published: </p>
                                <div className="flex flex-row gap-2">
                                    <label>
                                        <input type="radio" name="published" value="true" className="mr-1 accent-spotGrey" onChange={(e) => setpublished(e.target.value)} />
                                        True
                                    </label>
                                    <label>
                                        <input type="radio" name="published" value="false" className="mr-1 accent-spotGrey" onChange={(e) => setpublished(e.target.value)} />
                                        False
                                    </label>
                                </div>
                            </div>
                        </div>
                        {errorMessage && <p className="text-red-500 w-full text-center mb-[-12px]">{errorMessage}</p>}
                        <button onClick={handleSubmitAnnouncement} className="bg-lightGrey hover:bg-spotYellow text-spotGrey font-bold p-2 rounded mt-4">Save</button>
                    </div>
                </div>
            )}
        </div>
    );
}

export default Announcements;