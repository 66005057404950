import React, { useState, useEffect } from 'react';
import AWS from '../../../aws-config';
import { Line } from 'react-chartjs-2';
import 'chart.js/auto';
import 'chartjs-adapter-date-fns';

const APICalls = () => {
    const [apiData, setApiData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [timePeriod, setTimePeriod] = useState('monthToDate');
    const [selectedMethod, setSelectedMethod] = useState('');
    const [isAuthenticated, setIsAuthenticated] = useState(sessionStorage.getItem('authenticated') === 'true');
    const [password, setPassword] = useState('');

    const fetchData = async (period) => {
        try {
            const response = await fetch('https://your-lambda-endpoint.amazonaws.com/SpotAPI', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    period,
                    method: selectedMethod || 'ALL',
                }),
            });

            const result = await response.json();

            const formattedData = result.data.map(item => ({
                date: new Date(item.timestamp),
                averageLatency: item.averageLatency,
                method: item.method,
            }));

            setApiData(formattedData);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching API data:', error);
            setLoading(false);
        }
    };

    useEffect(() => {
        if (isAuthenticated) {
            fetchData(timePeriod);
        }
    }, [timePeriod, isAuthenticated, selectedMethod]);

    const handleTimePeriodChange = (event) => {
        setTimePeriod(event.target.value);
        setLoading(true);
    };

    const handleMethodChange = (event) => {
        setSelectedMethod(event.target.value);
    };

    const handlePasswordSubmit = () => {
        if (password.toString().toLowerCase() === 'password') {
            sessionStorage.setItem('authenticated', 'true');
            setIsAuthenticated(true);
        } else {
            alert('Incorrect password');
        }
    };

    const methods = [...new Set(apiData.map(item => item.method))];

    const data = {
        labels: apiData.map(item => item.date),
        datasets: selectedMethod ? [{
            label: selectedMethod,
            data: apiData.map(item => item.averageLatency),
            fill: false,
            backgroundColor: 'rgba(75,192,192,0.4)',
            borderColor: 'rgba(75,192,192,1)',
        }] : [{
            label: 'All Methods',
            data: apiData.map(item => item.averageLatency),
            fill: false,
            backgroundColor: 'rgba(255,227,116,0.4)',
            borderColor: 'rgba(255,227,116,1)',
        }],
    };

    const options = {
        scales: {
            x: {
                type: 'time',
                time: {
                    unit: 'day',
                    tooltipFormat: 'MMM dd, yyyy',
                },
            },
            y: {
                beginAtZero: true,
            },
        },
    };

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            handlePasswordSubmit();
        }
    };

    return (
        <div className='ml-20 p-8'>
            {isAuthenticated ? (
                <>
                    <h1 className='text-3xl font-bold'>API Average Latency Dashboard</h1>
                    <div className='flex flex-row'>
                        <div className='mb-4'>
                            <label className='mr-2' htmlFor='timePeriod'>Select Time Period: </label>
                            <select id='timePeriod' value={timePeriod} onChange={handleTimePeriodChange}>
                                <option value='monthToDate'>Month to Date</option>
                                <option value='quarterToDate'>Quarter to Date</option>
                                <option value='lastQuarter'>Last Quarter</option>
                                <option value='last6Months'>Last 6 Months</option>
                                <option value='last12Months'>Last 12 Months</option>
                            </select>
                        </div>
                        <div className='mb-4'>
                            <label className='mr-2' htmlFor='method'>Select Method: </label>
                            <select id='method' value={selectedMethod} onChange={handleMethodChange}>
                                <option value=''>All Methods</option>
                                {methods.map(method => (
                                    <option key={method} value={method}>{method}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                    {
                        loading ? (
                            <p>Loading...</p>
                        ) : (
                            <Line data={data} options={options} />
                        )}
                </>
            ) : (
                <div className='w-full h-full flex flex-col justify-center items-center'>
                    <div className='border shadow-lg rounded-xl p-8 flex flex-col justify-center items-center'>
                        <h2 className='w-full text-center'>Please enter the password to access the API data</h2>
                        <input
                            type='password'
                            value={password}
                            className='border p-2 rounded-lg w-full'
                            onChange={(e) => setPassword(e.target.value)}
                            onKeyDown={handleKeyPress}
                        />
                        <button className='px-4 py-2 mt-6 bg-spotYellow rounded-lg shadow-md' onClick={handlePasswordSubmit}>Submit</button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default APICalls;